import { createInertiaApp } from '@inertiajs/vue3'
import axios from 'axios'
import { createApp, h, reactive } from 'vue'
import * as Sentry from '@sentry/vue'
import { createLogger } from 'vue-logger-plugin'
import FloatingVue, { vTooltip } from 'floating-vue'
import './../../css/tooltips.css'
import 'floating-vue/dist/style.css'
import 'reflect-metadata'

const logger = createLogger({
  enabled: true,
  level: 'debug',
})

interface InertiaAppProps {
  pages: Record<string, any>
  id?: string
  mixin?: object
  store?: object
  onSetup?: (appInstance: any, store: any, props: any) => void
}

function getDeviceType(): 'android' | 'ios' | 'desktop' {
  const ua = navigator.userAgent || navigator.vendor || (window as any).opera

  if (/android/i.test(ua)) return 'android'
  if (/iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream) return 'ios'
  return 'desktop'
}

export function initInertiaApp(
  root: string,
  { pages, id, mixin, store, onSetup }: InertiaAppProps
) {
  return createInertiaApp({
    id: id ?? 'app',
    resolve: (name) => {
      logger.debug('Resolving page', name)
      return pages[`${root}/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
      logger.debug('Setting up Inertia app')
      const appInstance = createApp({ render: () => h(App, props) }).use(plugin)

      appInstance.use(FloatingVue, {
        themes: {
          light: {
            $extend: 'tooltip',
            delay: {
              show: 50,
              hide: 100,
            },
          },
        },
      })
      appInstance.directive('tooltip', vTooltip)

      appInstance.use(
        createLogger({
          enabled: true,
          level: 'debug',
          callerInfo: true,
          prefixFormat: ({ level, caller }) =>
            caller
              ? `[${level.toUpperCase()}] [${caller?.fileName}:${caller?.functionName}:${caller?.lineNumber}]\n`
              : `[${level.toUpperCase()}]\n`,
        })
      )

      if (import.meta.env.VITE_SENTRY_DSN)
        Sentry.init({
          app: appInstance,
          dsn: import.meta.env.VITE_SENTRY_DSN,
        })

      const provide = reactive({
        currentUser: null,
        attending: [],
        flash: {},
        errors: {},
        logout() {
          this.currentUser = null
        },
        ...store,
      })

      const authStore = reactive({
        id: null,
        logout: () => {},
      })
      appInstance.provide('auth', authStore)
      appInstance.provide('store', provide)
      appInstance.provide('device', getDeviceType())
      appInstance.provide('device_sharing_enabled', !!navigator.share)

      const modalStore = reactive({
        openModals: [],
        modals: [],
      })

      appInstance.provide('modalStore', modalStore)

      if (mixin) appInstance.mixin(mixin)

      if (onSetup) onSetup(appInstance, provide, props)

      initAuthInterceptor(authStore)

      appInstance.mount(el)
    },
  })
}


export function initAuthInterceptor(authStore: any) {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response.headers?.['x-inertia-location']) {
        console.info(
          '🛫 Interceptor: Inertia redirect',
          error.response.headers['x-inertia-location']
        )
        window.location.href = error.response.headers['x-inertia-location']
      }
      if (error.response.status === 401) {
        console.info('🫡 Interceptor: 401 error, logging out')
        authStore.logout()
      }
      throw error
    }
  )
}

export function duplicateInitialPageProps(store, props) {
  for (const key in props.initialPage.props) {
    if (key in store) {
      store[key] = props.initialPage.props[key]
    }
  }

  store.attending = props.initialPage.props.auth?.attending
  store.currentUser = props.initialPage.props.auth?.user
}
